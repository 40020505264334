.card-material-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: 100%;
}

.card-material-selector img {
    width: 100%;
    max-width: 300px;
    object-fit: cover;
}

.card-material-selector button {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #efefef;
    background: none;
    text-align: left;
    justify-content: flex-start;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    background: #efefef;
    cursor: pointer;
}

.card-material-selector h3 {
    font-size: 1rem;
    font-weight: 500;
}

.card-material-selector p {
    font-size: 0.8rem;
    font-weight: 400;
}

.custom-slide-navigators {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 1rem;
    gap: 1rem;
}

.form-splide-container {
    height: 100%;
    overflow: inherit;
}

.custom-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-direction: column;
    background: #fffffe;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 4px;
    border-radius: 20px;
}

.custom-pagination button {
    background: none;
    cursor: pointer;
    border-radius: 50%;
    padding: 5px;
    border: none;
}

.custom-pagination img, .custom-arrows img {
    width: 20px;
    object-fit: contain;
}

.custom-pagination button.active-page, .custom-pagination button:hover {
    background: #ececec;
}

.custom-arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: fit-content !important;
    padding: 1rem;
    border-radius: 20px;
    font-size: 14px;
}

.custom-arrows.next {
    opacity: 1;
}

.color-picker {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.color-picker > div {
    padding: 1rem;
    border-radius: 20px;
    background: #efefef;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.color-picker h2 {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
}

.frontside-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
}

.frontside-form > div {
    border-radius: 20px;
    background: #efefef;
    width: 100%;
    position: relative;
}

.frontside-form div, .backside-form div {
    margin: 0;
}

.frontside-form .form-container {
    padding: 1rem;
}

.frontside-form .cropButton {
    background: #fffffe;
}

.logo-checkboxes {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-block: 0.5rem;
}

.alignment-selector {
    display: flex;
    gap: .5rem;
}

.alignment-selector button {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background: #fffffe;
    color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alignment-selector button:hover, .alignment-selector button.active {
    background: #0a0a0a;
    color: #fffffe;
}

.inActiveSize {
    color: #cccccc;
}

.inActiveSize:hover {
    background: #0a0a0a;
    color: #fffffe;
}

.useLogoPrompt {
    position: absolute;
    /* center div */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    background: #efefef;
    z-index: 2;
    border-radius: 20px;
    padding: 1rem;
}

.backside-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backside-form {
    position: relative;
    text-align: center;
}

.backside-form > .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem;
    border-radius: 20px;
    background: #efefef;
}

.backside-form > .form-container .logo-selection.selected {
    background: #0a0a0a;
    color: #fffffe;
}

.backside-form > .form-container .logo-selection {
    padding: 1rem;
    border-radius: 20px;
    background: #fffffe;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    color: #0a0a0a;
    min-width: 300px;
    cursor: pointer;
    border: none;
}

.price-text {
    font-size: 1rem
}

.price-text > span {
    font-size: 0.75rem;
    padding-left: 4px;
    color: #cccccc;
}