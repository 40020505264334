.plans-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
    gap: 1rem;
    padding: 1rem 0;
}

.plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 5%) 0 1px 2px, rgba(0, 0, 0, 5%) 0px 2px 4px, rgba(0, 0, 0, 5%) 0px 4px 8px, rgba(0, 0, 0, 5%) 0px 8px 16px, rgba(0, 0, 0, 5%) 0px 16px 32px, rgba(0, 0, 0, 5%) 0px 32px 64px;
    background-color: #fff;
    gap: 1rem;
}

.plan.active {
    border: 3px solid #078080;
    position: relative;
}

.selected-label {
    display: none;
}

.plan.active .selected-label {
    display: block;
    position: absolute;
    left: -3px;
    top: -20px;
    background: #078080;
    padding: 0.5rem 1rem;
    border-radius: 10px 10px 10px 0;
    color: #fff;
}

.plan-skeleton {
    display: flex;
    gap: 0.5rem;
    height: 380px;
}

.billing_interval {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.billing_interval > p {
    color: #727272;
}

.billing_interval > div {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.pricing span {
    font-size: 2rem;
    font-weight: 500;
}

.pricing div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.pricing p {
    color: #727272;
    font-size: 12px;
}

.features-table {
    list-style: circle;
    text-align: left;
}

.features-table li {
    padding: 0.5rem 0;
}