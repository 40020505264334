.profile-container {
    text-align: center;
    border-radius: 20px;
    padding: 2rem 2rem;
    position: relative;
    background: #efefef;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1rem;
}

.profile-row-container {
    width: 100%;
    padding: 1rem;
    flex-direction: row;
    align-items: center;
}

.profile-row-container > div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    text-align: left;
}

.profile-buttons > a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-information > img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.profile-information > h2, .profile-row-information > div > h2 {
    font-weight: 500;
    font-size: 15px;
}

.profile-information > button {
    margin-top: 1rem;
}

.profile-information > p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
}

/*always takes the full width spacing the elements with the gap property equally*/
.profiles {
    padding-top: 1rem;
    display: grid;
    gap: 1.5rem 2rem;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    margin-bottom: 3rem;
    position: relative;
    justify-items: stretch;
}

.row-profiles {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.machKeinAuge {
    position: absolute;
    top: 1rem;
    left: 1rem;
    cursor: pointer;
}

.selector-options {
    display: flex;
    padding: 1rem 2rem;
    box-shadow: rgba(0, 0, 0, 5%) 3px 6px 12px;;
    gap: 1rem;
    border-radius: 20px;
    position: fixed;
    bottom: 2rem;
    right: 5rem;
    background-color: #ffffff;
}

.selection-ball {
    border-radius: 50%;
    border: 2px solid #0A0A0A !important;
    cursor: pointer;
    padding: 5px;
    display: flex;
    background: none;
}

.selection-ball.selected {
    background: #0A0A0A;
}

.selection-ball.selected > svg {
    stroke: #ffffff;
}

.selection-ball.top-right {
    position: absolute;
    top: 0;
    right: 1rem;
}

.selection-ball > svg {
    height: 1rem;
    width: 1rem;
    stroke: rgba(0, 0, 0, 0);
}

.selection-ball:hover > svg {
    stroke: #0A0A0A;
}

.selection-ball.selected > svg {
    stroke: #ffffff;
}

.editPopup > .popup-content {
    width: 80%;
}

.select_template {
    justify-content: center;
}

.splide__slide > div {
    margin: auto
}

.splide__pagination__page.is-active {
    background: #0A0A0A !important;
}

.Dropdown-option.is-selected, .Dropdown-option:hover {
    background-color: rgba(0, 0, 0, 5%) !important;
    color: #333;
}

.profile-row-information > img {
    height: 50px;
    width: 50px;
    margin: 0;
}

.profile-row-information > button {
    cursor: pointer;
    border-radius: 50%;
    border: none;
    margin: auto;
}

.filter-toggles {
    gap: 3rem !important;
}

.filter-toggles > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.sortDropControl {
    width: inherit !important;
    background-color: white !important;
    border: none !important;
}

.sortDropMenu {
    background-color: white !important;
    border-radius: 0.5rem;
    margin-top: 0 !important;
    border: none !important;
    box-shadow: rgba(0, 0, 0, 5%) 0px 1px 2px, rgba(0, 0, 0, 5%) 0px 2px 4px, rgba(0, 0, 0, 5%) 0px 4px 8px, rgba(0, 0, 0, 5%) 0px 8px 16px, rgba(0, 0, 0, 5%) 0px 16px 32px, rgba(0, 0, 0, 5%) 0px 32px 64px !important;
}

.edit-options-bar {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.edit-invite-button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
}

.pagination-spacer {
    position: fixed;
    width: calc(100% - 235px - 10rem);
    display: flex;
    justify-content: center;
    bottom: 1rem;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 5%) 0 1px 2px, rgba(0, 0, 0, 5%) 0 2px 4px, rgba(0, 0, 0, 5%) 0 4px 8px, rgba(0, 0, 0, 5%) 0 8px 16px, rgba(0, 0, 0, 5%) 0 16px 32px, rgba(0, 0, 0, 5%) 0px 32px 64px;
    border-radius: 20px;
    padding: 0.5rem;
}

.pagination > button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

.pagination > button:hover {
    background-color: #efefef;
}

.pagination-page:disabled, .pagination-page:disabled:hover {
    color: white;
    background: #0a0a0a;
}

/*rgba(0, 0, 0, 3%) 0px 1px 2px, rgba(0, 0, 0, 3%) 0px 2px 4px, rgba(0, 0, 0, 3%) 0px 4px 8px, rgba(0, 0, 0, 3%) 0px 8px 16px, rgba(0, 0, 0, 3%) 0px 16px 32px, rgba(0, 0, 0, 3%) 0px 32px 64px;*/