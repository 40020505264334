.filter-switch label {
    cursor: pointer;
    align-items: center;
    display: flex;
    margin: auto 5px;
    padding-bottom: 0 !important;
}

.filter-switch label.selected {
    color: #fffffe
}

.filter-switch input:checked label {
    color: #0A0A0A;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.radio-item {
    position: relative;
    height: 30px;
    display: flex;
    border-radius: .375rem;
    width: 4rem;
    justify-content: center;
}

.radio-container {
    color: rgba(10, 10, 10, 30%);
    padding: .25rem;
    display: inline-flex;
    border-radius: .375rem;
    background-color: #fafafa;
}

.radio-item.selected {
    box-shadow: rgba(0, 0, 0, 5%) 0px 1px 2px, rgba(0, 0, 0, 5%) 0px 2px 4px, rgba(0, 0, 0, 5%) 0px 4px 8px, rgba(0, 0, 0, 5%) 0px 8px 16px, rgba(0, 0, 0, 5%) 0px 16px 32px, rgba(0, 0, 0, 5%) 0px 32px 64px;
    background: #0A0A0A;
}

.filter-display-container > li {
    height: inherit;
    width: auto;
    padding: 0.25rem;
}

.filter-display-container > li.selected {
    border: #f8f8f8;
    background: white;
}

.filter-display-container {
    padding: 0;
    background: none;
}

.filter-display-container label.selected {
    color: #0A0A0A;
}