.sidebar {
    height: 96%;
    width: 225px;
    position: fixed;
    z-index: 1;
    background-color: #0A0A0A;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    border-radius: 1rem;
}

.sidebar__logo {
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: #fffffe;
    justify-content: space-between;
    align-items: center;
}

.title_sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-inline: auto;
    gap: 0.5rem;
    align-items: center;
    padding: 1rem;
}

.title_sidebar > p {
    font-weight: 500;
    color: white;
    height: 21px;
}

.beta_btn {
    color: white;
    text-align: center;
    min-width: inherit;
    background: #cccccc;
    border-radius: 20px;
    width: fit-content;
    padding: 0 1rem;
    margin: auto;
}

.sidebar__menu {
    position: relative;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    margin-inline: auto;
}

.sidebar__menu > a {
    padding: 0.6rem;
}

.sidebar__menu__item {
    display: flex;
    align-items: center;
    place-content: flex-start;
    font-size: 18px;
    color: #BFBFBF;
}

.sidebar__menu__item.active {
    color: #ffffff;
}

.sidebar__menu__item.active > .sidebar__menu__item__icon > svg {
    stroke: white;
}

.sidebar__menu__item__icon {
    width: 28px;
    display: flex;
    margin-right: 1rem;
}

.sidebar__menu__item__icon svg {
    margin: auto;
    stroke: #BFBFBF;
}

.sidebar__logo img {
    margin-bottom: 8px;
    height: 32px;
}

.sidebar__menu__item__text {
    width: 60%;
}

.footer {
    width: 100%;
    position: absolute;
    color: #fffffe;
    font-size: 10px;
    bottom: 0;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.footer > div > a {
    color: white;
}

.footerDropControl {
    width: 4rem;
    margin-inline: auto;
    background-color: #ececec;
    border-radius: 0.5rem;
}

.footerDropMenu {
    width: 4rem;
    bottom: 100%;
    top: inherit;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
    background-color: #ececec;
    margin-bottom: 0;
    border-radius: 0.5rem;
}

.logout-sidebar {
    position: absolute;
    bottom: 3rem;
    padding-inline: 3rem;
    width: 100%;
}

.logout-btn {
    border: none;
    background: none;
    cursor: pointer;
}

.plan-button {
    height: fit-content;
    min-width: fit-content;
}