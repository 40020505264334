.phone_preview {
    text-align: center;
    padding: 1rem;
    border-radius: 1.5rem;
    height: 500px;
    width: 270px;
    color: #0A0A0A;
    background-color: #f8f5f2;
    box-shadow: rgba(0, 0, 0, 5%) 0px 1px 2px, rgba(0, 0, 0, 5%) 0px 2px 4px, rgba(0, 0, 0, 5%) 0px 4px 8px, rgba(0, 0, 0, 5%) 0px 8px 16px, rgba(0, 0, 0, 5%) 0px 16px 32px, rgba(0, 0, 0, 5%) 0px 32px 64px;
}

.preview_container {
    overflow-y: auto;
    height: 100%;
    border-radius: 33px;
    background: #f8f5f2;
}

.preview_profile_thumbnail {
    overflow: hidden;
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    border: 1px black;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 3%) 0px 1px 2px, rgba(0, 0, 0, 3%) 0px 2px 4px, rgba(0, 0, 0, 3%) 0px 4px 8px, rgba(0, 0, 0, 3%) 0px 8px 16px, rgba(0, 0, 0, 3%) 0px 16px 32px, rgba(0, 0, 0, 3%) 0px 32px 64px;
    max-width: 270px;
    min-height: 400px;
}

.new_template_container {
    border: none;
    min-height: 483px;
    cursor: pointer;
    width: 300px;
}

.profile-back {
    line-height: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding-top: calc(205 / 370 * 86.5%);
    aspect-ratio: 16/9;
    height: auto;
}

.back-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 25px;
    object-fit: cover;
    aspect-ratio: 16/9;
    animation: skeleton-loading 1s linear infinite alternate;
}

.logo svg {
    width: 121px;
}

.brandidentity {
    padding: 15px 0 5px;
    display: flex;
    justify-content: center;
}

.profile-avatar {
    height: 48px;
    margin: 0 auto;
}

.avatar-inline {
    height: 100%;
    margin-right: 0;
}


.account-link {
    color: #fffffe;
    width: 50%;
    height: 50%;
}

ul {
    list-style: none;
}

.account-list {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 18px 0;
}

.additional-accounts {
    padding: 25px 0 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.username {
    padding-bottom: 10px;
}

.username h1 {
    font-family: "Lora", serif;
    font-weight: 700;
    line-height: 1;
    font-size: 1.5rem;
}

.userinfo h2 {
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: 10px;
    font-size: 0.9em;
}

#Profilbild {
    width: 1px;
}

.account-item {
    background-color: #0A0A0A;
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 0 5px;
}

.account-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.account-form-container img {
    box-shadow: rgba(0,0,0, 5%) 3px 6px 12px;
}

.account-option {
    display: block;
    background-size: contain;
    float: left;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    background-color: #bbbbbb;
}

.account-item > img {
    width: 50%;
    height: 50%;
}

.account-icons {
    display: flex;
    flex-wrap: wrap;
}

.account-icons:after {
    content: '';
    flex: auto;
}

.account-icons > button {
    margin-block: 5px;
    cursor: pointer;
}

.colorButton {
    color: #fffffe;
}

.quantityIndicator {
    position: absolute;
    bottom: -2px;
    right: -2px;
    background: #0A0A0A;
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    border-radius: 100%;
    color: #fffffe;
}

.quantityIndicator > svg {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.account-header {
    display: flex;
    height: 38px;
    gap: 0.25rem;
    align-items: center;
    justify-content: space-between;
}

.account-header .account-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.account-container {
    background: #efefef;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 1rem;
    text-align: left;
    box-shadow: rgba(0,0,0, 5%) 3px 6px 12px;
}

.account-container input {
    background-color: #fafafa;
}

.account-size {
    justify-content: flex-end;
}

.btn-map {
    border-radius: 25px;
    background-color: #078080;
    padding: 6px;
    aspect-ratio: 370/148;
}

.mapImg {
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
}

.floating {
    width: 35%;
    position: fixed;
    display: flex;
    bottom: 0;
    z-index: 5;
    padding: 8px 0 2rem;
}

#backgroundUpload {
    width: 0;
}

.navigatorContainer {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.delete-profile {
    position: absolute;
    top: 0;
    right: 12px;
    width: 25px;
    height: 25px;
    padding-bottom: 5px;
    border-radius: 50%;
    background: #E84855;
    border: none;
    color: white;
    text-align: center;
    cursor: pointer;
    font: 20px Arial, sans-serif;
    line-height: 0.9rem;
}

.addCustomLogoContainer {
    background-color: #efefef;
    border-radius: 20px;
    padding: 1rem;
    position: relative;
}

.addCustomLogoContainer .cropButton {
    background: #fffffe;
}

.advertPlan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.advertPlan > p {
    font-size: 0.8rem;
    text-align: center;
    color: #0A0A0A;
}

.advertPlan > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.dynamic-text {
    position: relative;
    overflow: hidden;
    width: 150px;
    height: 100%;
}

.dynamic-text:before {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: attr(data-dynamic-before);
    transition: all .3s;
}

.dynamic-text::after {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    content: attr(data-dynamic-after);
    transition: all .3s;
}

.dynamic-button:hover .dynamic-text::before {
    top: -60px;
}
.dynamic-button:hover .dynamic-text::after {
    top: 0;
}

.branding-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}