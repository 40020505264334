@import url("https://fonts.googleapis.com/css2?family=Lora:wght@700&family=Poppins:wght@400;500;700&display=swap");

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    background-color: #ffffff;
}

.hidden {
    display: none;
}

.container {
    margin-left: 235px;
    min-height: 300px;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 3rem 5rem 1rem;
}

h1 {
    font-size: 2.5rem;
    font-weight: 500;
}

a {
    text-decoration: none;
}

.btn {
    background: #0A0A0A;
    color: #ffffff;
    border: 1px solid #393939;
    padding: 6px 1rem;
    border-radius: 25px;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    font-family: inherit;
    height: 40px;
    min-width: 10rem;
    box-shadow: rgba(0, 0, 0, 5%) 0px 1px 2px, rgba(0, 0, 0, 5%) 0px 2px 4px, rgba(0, 0, 0, 5%) 0px 4px 8px, rgba(0, 0, 0, 5%) 0px 8px 16px, rgba(0, 0, 0, 5%) 0px 16px 32px, rgba(0, 0, 0, 5%) 0px 32px 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.btn:focus {
    outline: none;
}

.btn:active {
    transform: scale(0.98);
}

.btn:disabled {
    background-color: white;
    border: none;
    color: #ccc;
    cursor: inherit;
}

.newWorker {
    display: flex;
}

.worker {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.codeView {
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0.5rem 0;
}

.workerTemplate {
    display: flex;
    flex-direction: column;
    background-color: #efefef;
    border-radius: 20px;
    padding: 1rem 1rem 0;
}

.worker > button {
    margin-block: auto;
}

.worker-information {
    display: flex;
}

.worker-information > * {
    margin-block: auto;
    margin-inline: 1rem;
}

.form-control {
    margin: 20px 0;
    text-align: center;
}

.input-check {
    display: flex;
    align-items: center;
    gap: .5rem;
    margin: 0;
}

.input-check input {
    border: 1px solid #cccccc !important;
}

/*.deleteBtn {*/
/*    color: #F45D48;*/
/*    margin-block: auto;*/
/*    background: #ffffff;*/
/*    border: #ececec;*/
/*    box-shadow: none;*/
/*}*/

.form-control label {
    display: block;
    padding-bottom: .5rem;
}

.file {
    opacity: 0;
    width: 1px !important;
    height: 1px !important;
    position: absolute;
}

.form-control input, .input-check input {
    width: 100%;
    height: 40px;
    padding: 1rem;
    border-radius: 10px;
    font-size: 1rem;
    border: 1px solid #ececec;
    text-align: center;
}

.form-control-check {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-control-check label {
    flex: 1;
}

.form-control-check input {
    flex: 2;
    height: 20px;
}

footer {
    margin-top: 30px;
    text-align: center;
}

.file {
    opacity: 0;
    width: 1px;
    height: 1px;
    position: absolute;
}

.file-input label {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-right: 0
}

.file-input label:active {
    transform: scale(0.98);
}

.text-container {
    width: 100%;
    margin-left: 16px;
    margin-block: auto;
}

.profile {
    min-width: 350px;
    text-align: center;
    margin: 20px;
    color: #078080;
}

.profile-image {
    border-radius: 50%;
    object-fit: cover;
}

.round {
    width: 86px;
    height: 86px;
    transform: translateY(-50%);
    border: 2px solid #fffffe;
}

.profile-inline {
    transform: inherit;
}

.big {
    width: 150px;
    height: 150px;
}

.profile-picture {
    height: 150px;
    margin: 10px;
}

.small {
    width: 63px;
    height: 63px;
    padding: 10px;
    object-fit: scale-down;
}

.image-container {
    background: #fffffe;
    border-radius: 50%;
    width: 63px;
    height: 63px;
}

.bigButton {
    background-color: #078080;
    width: 86.4%;
    min-height: 58px;
    padding: 0.75rem 1.25rem;
    border-radius: 10rem;
    color: #fffffe;
    margin: 0 0 10px;
    justify-content: center;
    align-items: center !important;
    font-weight: bold;
    display: flex;
}

.cardPreview {
    width: 100%;
    border-radius: 15px;
    max-width: 300px;
    box-shadow: rgba(0, 0, 0, 5%) 0px 1px 2px, rgba(0, 0, 0, 5%) 0px 2px 4px, rgba(0, 0, 0, 5%) 0px 4px 8px, rgba(0, 0, 0, 5%) 0px 8px 16px, rgba(0, 0, 0, 5%) 0px 16px 32px, rgba(0, 0, 0, 5%) 0px 32px 64px;
}

.activeSize {
    background: #078080;
    color: #fffffe;
    cursor: inherit;
}

.rowSelector {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.activeSize:hover {
    background: #078080;
    color: #fffffe;
}

.editor_container {
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.editor_preview {
    max-width: 450px;
    display: flex;
    margin-block: auto;
}

.lineText {
    border-block: 1px solid #232323;
    color: #232323;
    opacity: 15%;
    padding: 4px;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1400px) {
    .container {
        margin-left: 250px;
    }

    .cardPreview {
        border-radius: 2vw;
    }
}

.checkbox {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 40px;
    border: none;
    background: none;
}

.checkbox svg {
    border: 2px solid #0a0a0a;
    border-radius: 50%;
    padding: 3px;
    color: #efefef;
    flex-shrink: 0;
}

.checkbox:hover svg {
    color: #0a0a0a;
}

.checked svg, .checked:hover svg {
    color: #fffffe;
    background: #0a0a0a;
}


.centerMsg {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centerMsg > div {
    display: flex;
}

.centerMsg > button {
    width: fit-content;
}

#nfcSvg {
    position: absolute;
    top: 0;
}

#bumpeeSvg {
    position: absolute;
    top: 0;
}

.popup {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1rem 5rem;
    justify-content: center;
    align-items: center;
    background-color: white;
    gap: 1rem;
}

.template_select_popup {
    padding: 1rem;
}

.popup-content {
    width: inherit;
    border-radius: 1rem;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
    position: relative;
}

.header p {
    font-size: 1rem
}

.header p > span {
    font-size: 0.75rem;
    padding-left: 4px;
    color: #cccccc;
}

.options-navigation {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.whiteButton {
    background: #fff;
    border: #f8f8f8;
    color: #0A0A0A;
    box-shadow: rgba(0, 0, 0, 5%) 0px 1px 2px, rgba(0, 0, 0, 5%) 0px 2px 4px, rgba(0, 0, 0, 5%) 0px 4px 8px, rgba(0, 0, 0, 5%) 0px 8px 16px, rgba(0, 0, 0, 5%) 0px 16px 32px, rgba(0, 0, 0, 5%) 0px 32px 64px;
}

.greyButton {
    background: #efefef;
    border: #ececec;
    color: #0A0A0A;
    box-shadow: none;
}

.green_btn {
    color: #078080;
    margin-inline: auto;
}

.greyed_out_btn {
    color: rgba(10, 10, 10, 30%);
}

.btn > svg {
    height: auto;
    max-height: 1rem;
}

.selectBtn {
    color: #0A0A0A;
    background-color: white;
    border: none;
}

.card_container {
    text-align: center;
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-block: auto;
    gap: 1rem;
}

.add-form > div:first-child {
    margin-top: 0;
}

.login-container {
    display: flex;
    justify-content: space-evenly;
    height: 100vh;
}

.login-sheet {
    display: flex;
    flex-direction: column;
    width: 450px;
    text-align: center;
    height: fit-content;
    margin: auto;
}

.login-sheet > svg {
    margin-inline: auto;
}

.loginField-container {
    width: 50%;
    height: 100%;
    display: flex;
    background: #efefef;
}

.login-info-container {
    width: 50%;
    height: 100%;
    position: relative;
}

.login-info-container > div {
    position: absolute;
    width: 110%;
    z-index: -1;
    left: -5%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    top: 50%;
    transform: translateY(-50%);
}

.login-info-container > div > img {
    width: 80%;
    object-fit: contain;
    max-height: 80%;
    margin: auto;
}

.login-info-container > div > div {
    width: 70%;
    margin-inline: auto;
    text-align: center;
}

.signIn-container {
    margin-block: auto;
    color: #0A0A0A;
    padding: 2rem;
    height: 100%;
}

.signIn-container p {
    margin-bottom: 1rem;
}

.email-login {
    margin-block: 1rem;
}

.register {
    text-decoration: underline;
    border: none;
    background: none;
    font-size: 1rem;
    cursor: pointer;
}

.rectangle {
    aspect-ratio: 4044 / 2550;
    animation: skeleton-loading 1s linear infinite alternate;
    margin: auto;
}

.splide__arrow {
    height: 3em !important;
    width: 3em !important;
    background-color: #efefef !important;
}

.card_template_preview {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    border-radius: 1rem;
    background-color: #efefef;
    text-align: center;
    gap: 1rem;
    width: fit-content;
}

.template-skeleton {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.mini_card_preview {
    width: 300px;
    aspect-ratio: 4044 / 2550;
    border-radius: 1rem;
}

.card_template_preview > a {
    margin-inline: auto;
}

.template_previews {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

.mini_profile_preview {
    overflow: hidden;
    height: 20rem;
    margin-bottom: 1rem;
    border-radius: 0;
}

.popup_top_left {
    position: absolute;
    left: 0;
    top: 0;
    margin: 1rem 1rem;
}

.template-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    gap: 1rem;
}

.template-container div {
    margin: 0;
}

.template-container > ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.second-level-btn {
    background-color: #FAFAFA;
    border-color: #F8F8F8;
}

.add-form {
    width: calc(400px + 2rem);
    margin-inline: auto;
    max-height: calc(100vh - 85px - 1rem - 86px - 3rem - 1rem);
}

.form-height {
    max-height: calc(100vh - (4rem + 85px + 1rem + 86px + 40px + 1rem));
    overflow-y: auto;
    padding: 0 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.profile_row_preview {
    display: flex;
    margin: auto;
    gap: 1rem;
}

.profile-text {
    background: #fffffe;
    display: flex;
}

.splide__slide img {
    vertical-align: inherit !important;
}

.template_editing_preview {
    flex-direction: column;
    margin-block: auto;
    gap: 1rem;
}

.closePopupButton {
    position: absolute;
    left: 1rem;
    top: 1rem;
    min-width: 2rem;
    min-height: 2rem;
    aspect-ratio: 1;
    padding: 5px;
    display: flex;
}

.closePopupButton > svg {
    margin: auto;
    height: auto;
}

.Dropdown-control {
    padding: 8px 52px 8px 20px;
}

.Dropdown-menu {
    border: 1px solid #efefef;
    margin-top: 5px;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

.profilbild-container {
    display: flex;
}

.cropButton {
    min-width: 3rem;
}

.picture-upload-container {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}

.cropPreview {
    max-width: 60vw !important;
    max-height: 60vh !important;
}

.setting-widgets {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

.setting-widget {
    justify-items: center;
    gap: 1rem;
    padding: 2rem 1rem;
    border: none;
    background: none;
    border-radius: 1rem;
    min-width: 5rem;
    box-shadow: rgba(0, 0, 0, 5%) 0 1px 2px, rgba(0, 0, 0, 5%) 0 2px 4px, rgba(0, 0, 0, 5%) 0 4px 8px, rgba(0, 0, 0, 5%) 0 8px 16px, rgba(0, 0, 0, 5%) 0 16px 32px, rgba(0, 0, 0, 5%) 0 32px 64px;
}

.setting-widget-info {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.setting-widget-info > p {
    margin-block: auto;
    font-size: 1rem;
    width: max-content;
}

.setting-widget-info > svg {
    flex-shrink: 0;
    margin-block: auto;
}

.setting-widget:active {
    transform: scale(0.98);
}

.template-select-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 5%) 0px 1px 2px, rgba(0, 0, 0, 5%) 0px 2px 4px, rgba(0, 0, 0, 5%) 0px 4px 8px, rgba(0, 0, 0, 5%) 0px 8px 16px, rgba(0, 0, 0, 5%) 0px 16px 32px, rgba(0, 0, 0, 5%) 0px 32px 64px;
    border-radius: 1rem;
    text-align: center;
    max-width: 500px;
    height: 100%;
    border: none;
    text-decoration: none;
    background: none;
}

.template-select-container > * {
    width: fit-content;
    margin: auto;
    color: #0A0A0A;
}

.template-select-container > div {
    color: white;
}

.template-select-container > img {
    width: 100%;
    object-fit: contain;
    max-height: 325px;
    padding-block: 2rem;
}

.filter-options {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #efefef;
    width: 100%;
    border-radius: 20px;
}

.filter-options > div {
    display: flex;
    gap: 0.5rem;
}

.filter-search{
    position: relative;
}

.filter-search > input {
    width: 16rem;
    height: 40px;
    padding: 1rem calc(1rem + 16px);
    border-radius: 10px;
    font-size: 1rem;
    border: 1px solid #ececec;
}

.filter-search > svg {
    position: absolute;
    top: 50%;
    transform: translate(11px, -50%);
}

.filter-search > button {
    border: none;
    background: none;
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
    right: 11px;
}

.workers {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 70vh;
    gap: 1rem;
    position: relative;
}

.optionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.optionButton > svg {
    height: auto;
    max-height: 18px;
    margin: 0;
}

.usedIndicator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    background: rgba(244, 190, 72, 0.1);
    border: 1px solid #f4be48;
    color: #0a0a0a;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
    font-weight: lighter;
    font-size: 12px;
}

.usedIndicator > svg {
    height: 1rem;
    width: 1rem;
    background: #f4be48;
    border-radius: 50%;
    padding: 2px;
    color: white;
}

.usedActive {
    background: rgba(7, 128, 128, 0.1);
    border: 1px solid #078080;
}

.usedActive > svg {
    background: #078080;
}

.bullet-point-container {
    max-width: 600px;
    text-align: left;
    margin: auto;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding: 1rem
}

.bullet-point svg {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
}

.bullet-point {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 100%;
}

.bullet-point h2 {
    font-weight: 500;
}

.copyButton {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 4px;
    border: none;
    cursor: pointer;
    text-align: left;
    background: none;
}

.copyButton > svg {
    height: 2rem;
    width: 2rem;
    padding: 6px;
}

.copyButton:hover > svg {
    background-color: white;
    color: #0a0a0a;
    border-radius: 50%;
}

table {
    border-collapse: collapse;
    width: 100%;
}

td, th {
    border: 1px solid #ececec;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #efefef;
}

.editSettings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.editSettings > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.editSettings > button {
    margin-top: 1rem;
}

.editSettings label {
    font-weight: 500;
    font-size: 1rem;
}

.editSettings h1 {
    font-weight: 500;
    font-size: 1.5rem;
}

.editSettings > div {
    width: 100%;
    padding-block: 0.5rem;
    border-bottom: 1px solid #ececec;
}

.editSettings > div:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.logo-company {
    max-width: 150px;
    max-height: 80px;
    object-fit: contain;
}

.containerTopButton {
    position: absolute;
    top: 0;
    background: #fffffe;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
}

.containerTopButton svg {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
}

.containerTopButton.right {
    right: 0;
    border-radius: 0 0 0 20px;
}

.containerTopButton.left {
    left: 0;
    border-radius: 0 0 20px 0;
}