.preis {
    display: flex;
    margin-block: auto;
    font-size: 16px;
}

.preisAngaben {
    flex-direction: column;
    margin-left: 2rem;
    padding-top: 8px;
}

.preisDetails {
    font-size: 10px;
    color: #cccccc;
    margin-top: -8px;
}

.preisDetails > p { margin-top: auto;}

.preisAngaben p {
    text-align: left;
}

.discount {
    color: #f45d48;
}

.alterPreis {
    text-decoration: line-through;
    color: #cccccc;
}

.schild {
    font-weight: 500;
}

.anzahl {
    margin-left: 1rem;
    margin-block: auto;
}

.bottom {
    margin-top: 1rem;
}
.bottom > div {
    width: 100%;
    display: flex;
}

.bottom > button {
    width: 258px;
    margin-right: 1rem;
}

.checkout {
    margin-inline: 3rem;
}

.header-image {
    object-fit: contain;
    max-height: 350px;
}
