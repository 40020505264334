.welcome-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.welcome-container h2 {
    font-weight: 500;
}

.welcome-container > div {
    display: flex;
    position: relative;
}

.welcome-main-side {
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: white;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.welcome-header {
    padding: 1rem;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
}

.welcome-header > * {
    display: flex;
    justify-content: center;
    flex: 1;
    flex-shrink: 0;
}

.welcome-header > *:first-child {
    justify-content: flex-start;
}

.welcome-header > *:last-child {
    justify-content: flex-end;
}

.welcome-header > a {
    text-decoration: none;
    color: #0A0A0A;
}

.welcome-header > div {
    align-items: center;
    flex-direction: column;
}

.welcome-main-side a>div {
    display: flex;
    align-items: center;
}

.welcome-bullet-points {
    height: 100%;
    gap: 2rem !important;
    display: flex;
    flex-direction: column;
    max-width: 450px;
    padding: 2rem 0;
    text-align: left;
}

.welcome-info-side {
    width: 30%;
    background-color: #0A0A0A;
    color: white;
    justify-content: space-between;
    padding: 2rem;
    gap: 2rem;
    text-align: center;
    max-width: 400px;
}

.welcome-info-side > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.welcome-bullet-points > * {
    animation: fadeInFromBottom 0.8s ease-in-out 0s 1 forwards;
    opacity: 0;
}

.welcome-bullet-points > *:nth-child(1) {
    animation-delay: 0s;
}

.welcome-bullet-points > *:nth-child(2) {
    animation-delay: 1s;
}

.welcome-bullet-points > *:nth-child(3) {
    animation-delay: 2s;
}

.welcome-bullet-points > *:nth-child(4) {
    animation-delay: 3s;
}

.welcome-bullet-points > *:nth-child(5) {
    animation-delay: 4s;
}

@keyframes fadeInFromBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.welcome-first-slide {
    gap: 1rem;
    min-width: 400px;
    padding: 1rem 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.welcome-first-slide > div > a > div {
    background: white;
}

.welcome-slide-control {
    padding-block: 2rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.welcome-navigation {
    position: absolute;
    bottom: 3rem;
    display: flex;
    gap: 1rem;
}

.welcome-back-button {
    display: flex;
    justify-content: center;
    min-width: inherit;
}

.welcome-back-button > svg {
    height: inherit;
}

.welcome-footer {
    gap: 1rem;
    padding: 1rem;
    align-items: center;
}